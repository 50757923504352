import React from 'react';

import featuredImage from '@images/header-images/c-and-cpp-development-featured-image.jpg';
import downloadImage from '@images/c-and-cpp-development/about-us.png';
import ourArticlesImage from '@images/c-and-cpp-development/our-articles.png';
import imgOnDesktop from '@images/react-development/lets-talk-on-desktop.png';

import * as styles from './payload.module.scss';

export const headerProps = {
  title: 'C/C++ Development Services',
  showImageOnMobile: true,
  src: 'c-and-cpp-development-header-image.png',
  srcSmall: 'codilime_golang_header_small.png',
  displayTopBanner: false,
  className: styles.header,
};

export const letsTalkData = {
  firstHeading: (
    <>
      Get A+ results with <span className="bold-font">C</span> or <span className="bold-font">C++</span>!
    </>
  ),
  id: 'c-and-cpp-lets-talk',
  buttonTitle: 'Start C/C++ project',
  buttonHref: '#contact',
  imgAlt: 'start building a c or cpp project',
  downloadImage: imgOnDesktop,
  classNames: {
    title: styles.boxTitle,
    firstRow: styles.firstRow,
    imageContainer: styles.imageContainer,
    buttonWithLink: styles.buttonWithLink,
    descriptionContainer: styles.descriptionContainer,
  },
};

export const seoProps = {
  title: 'C/C++ Software Development Services Company CodiLime',
  description:
    "Want to build a project in C/C++? Choose an experienced strategic partner. We've been developing network solutions since 2011. See how we help.",
  featuredImage: featuredImage,
};

export const aboutUsSectionData = {
  firstParagraph: (
    <>
      Want to develop a new project based on reliable, verified technology? The C or C++ programming languages could be
      your go-to solution. These proven languages can fulfill even the most demanding requirements and are great for
      software development projects, including operating systems, games, embedded systems, and more.
    </>
  ),
  secondParagraph: (
    <>
      At CodiLime, <strong>we have been using C and C++ on a daily basis since 2011</strong> – both in projects for
      clients and for internal purposes. Because we know these languages inside out, we can (and will) help you boost
      your software-based business!
    </>
  ),
  buttonLabel: 'Let’s work together',
  imageOnRight: downloadImage,
  imageAltText: 'c and cpp development services',
  spaceOnMobile: true,
  classNames: { innerWrapper: styles.innerWrapper, customStylesButton: styles.customStylesButton },
};

export const howWeCanHelpSectionData = {
  sectionTitle: 'How can our C/C++ developers help you?',
  sectionId: 'how-we-can-help-you',
  firstColumn: [
    'C/C++ development in line with the latest standards.',
    'Development in Linux, Unix, BSD and Windows environments.',
    'Experts in low-level networking, interfacing with system kernels and hardware.',
  ],
  secondColumn: [
    'Proficiency with communication libraries, with DPDK at the center.',
    'Working with a large legacy codebase to extend functionalities and fix critical bugs.',
  ],
};

export const ourApproachData = {
  sectionTitle: 'C/C++ development – our approach',
  sectionId: 'our-approach',
  classNames: { singleBoxDescriptionStyles: styles.singleBoxDescriptionStyles },
};

export const articlesData = {
  standardSectionProps: {
    title: 'More about C/C++ – our publications',
    id: 'our-articles',
  },
  reactArticlesParagraph:
    'C/C++ deserves more space than just one page! If you want to know how C and C++ are different from other popular programming languages, or if you are interested in real-life C++ use cases, check out our articles and case study.',
  buttonListProps: {
    buttonsData: [
      {
        id: '1',
        title: 'Python vs. C++ – what is the difference between these languages?',
        href: '/blog/python-vs-cpp-what-is-the-difference-between-these-languages/ ',
      },
      {
        id: '2',
        title: 'Rust vs. C++—the main differences between these popular programming languages',
        href: '/blog/rust-vs-cpp-the-main-differences-between-these-popular-programming-languages/',
      },
      {
        id: '3',
        title: 'Go vs. C++ - key differences',
        href: '/blog/go-vs-c-plus-plus/',
      },
      {
        id: '4',
        title: 'Rust vs C: safety and performance in low-level network programming',
        href: '/blog/rust-vs-c-safety-and-performance-in-low-level-network-programming/',
      },
      {
        id: '5',
        title: 'Building a microservices security platform',
        href: '/case-studies/',
      },
    ],
  },

  imageData: {
    src: ourArticlesImage,
    alt: 'our most popular publications about c and cpp',
  },
  classNames: { customButton: styles.customButton },
};

export const trustedByData = {
  title: 'What do our clients say about us?',
  className: styles.trustedBySection,
};
