// extracted by mini-css-extract-plugin
export var boxTitle = "payload-module--box-title--af28a";
export var buttonWithLink = "payload-module--button-with-link--20feb";
export var contactTitle = "payload-module--contact-title--5bb26";
export var contactUsSection = "payload-module--contact-us-section--0a2d5";
export var customButton = "payload-module--custom-button--3a390";
export var customStylesButton = "payload-module--custom-styles-button--51980";
export var descriptionContainer = "payload-module--description-container--b3a41";
export var firstRow = "payload-module--first-row--44570";
export var header = "payload-module--header--0697f";
export var imageContainer = "payload-module--image-container--96aab";
export var innerWrapper = "payload-module--inner-wrapper--61c5c";
export var section = "payload-module--section--d5a1c";
export var singleBoxDescriptionStyles = "payload-module--single-box-description-styles--a5631";
export var trustedBySection = "payload-module--trusted-by-section--00cac";