import React from 'react';

import performanceIcon from '@images/golang-development/why-use-golang/performance.svg';
import flexibleIcon from '@images/rust-development/we-help-you/monitoring-app.svg';
import reusableIcon from '@images/react-development/why-use-react/reusable.svg';
import crossPlatformIcon from '@images/rust-development/why-use-rust/cross-platform.svg';

export const whyUseCAndCppData = {
  sectionTitle: 'Why use C or C++ for your project?',
  sectionId: 'why-use-c-cpp',
  firstParagraph: (
    <>
      According to the Annual C++ Developer Survey “Lite”, this programming language is the most often used in fields
      like gaming, hardware/IoT, networking, general and specific engineering (e.g. power management), and machine
      learning, as well as for developer tools like compilers, or code editors.
    </>
  ),
  secondParagraph: <> What makes C and C++ leading languages for these areas? You can find a few reasons below!</>,
  methods: [
    {
      src: flexibleIcon,
      alt: 'Flexible and cross-platform',
      title: 'Flexible and cross-platform',
      subtitle: (
        <>
          <strong>C and C++ are flexible programming languages</strong> that can be used in multiple ways. Moreover,
          they offer great portability – they work on almost every platform, including embedded systems.
        </>
      ),
    },
    {
      src: reusableIcon,
      alt: 'C and C++ are excellent performance and speed of execution',
      title: 'Fast',
      subtitle: (
        <>
          C and C++ are two of the most efficient languages, with <strong>excellent performance</strong> and fast
          execution speed. One of the reasons why these languages are so fast is that many instructions are directly
          mapped to an assembly language that works closer to the hardware.
        </>
      ),
    },
    {
      src: crossPlatformIcon,
      alt: 'Scalability',
      title: 'Scalability',
      subtitle: (
        <>
          These programming languages are a great option if you are unsure how much your application will expand. C and
          C++ work equally well on smaller and larger amounts of data. They also effectively manage resource-intensive
          applications.
        </>
      ),
    },
    {
      src: performanceIcon,
      alt: 'Great community',
      title: 'Great community',
      subtitle: (
        <>
          Both <strong>C and C++ are veterans on the market</strong> – over the years, they have gained a solid, active
          community that is still working on language development. There is little chance of your project encountering
          an issue that has not already been solved.
        </>
      ),
    },
  ],
};
