import React from 'react';

import SEO from '@commons/SEO';
import Layout from '@src/layouts';
import { Header } from '@commons/header/Header';
import { AboutUsSection } from '@pages-impl/shared/about-us-section/AboutUsSection';
import { BulletedSection } from '@commons/bulleted-section/BulletedSection';
import { WhyUseTechnology } from '@commons/why-use-technology/WhyUseTechnology';
import { OurApproachSection } from '@pages-impl/shared/our-approach/OurApproachSection';
import { TrustedBySection } from '@commons';
import { SectionWithArticles } from '@pages-impl/shared/section-with-articles/SectionWithArticles';
import { LetsTalkSection } from '@pages-impl/shared/lets-talk-section/LetsTalkSection';
import {
  headerProps,
  seoProps,
  aboutUsSectionData,
  howWeCanHelpSectionData,
  ourApproachData,
  articlesData,
  trustedByData,
  letsTalkData,
} from '@pages-impl/c-and-cpp-development/payload';

import { whyUseCAndCppData } from '@pages-impl/c-and-cpp-development/why-use-c/payload';

import * as styles from './c-and-cpp-development.module.scss';

export default function CAndCppDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Develop your C/C++ project with us"
      {...props}
    >
      <SEO {...seoProps} />
      <Header {...headerProps} />
      <AboutUsSection {...aboutUsSectionData} />
      <BulletedSection {...howWeCanHelpSectionData} />
      <WhyUseTechnology
        {...whyUseCAndCppData}
        className={{ section: styles.whyUseTechnologies, singleBoxDescription: styles.singleBoxDescription }}
      />
      <LetsTalkSection {...letsTalkData} />
      <OurApproachSection {...ourApproachData} />
      <TrustedBySection {...trustedByData} />
      <SectionWithArticles {...articlesData} />
    </Layout>
  );
}
